import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "item"]

  connect() {
  }

  filter() {
    const query = this.inputTarget.value.toLowerCase()

    this.itemTargets.forEach((item) => {
      const visibleText = item.textContent.toLowerCase()
      const hiddenText = item.dataset.searchText?.toLowerCase() || ''
      item.style.display = visibleText.includes(query) || hiddenText.includes(query) ? "" : "none"
    })
  }
}
